import React, { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navigation/Navbar";
import { graphql } from "gatsby";
import { componentBuilder } from "../helpers/componentsHelper";
import SeoHelmet from "../components/Seo/SeoHelmet";
import { componentsOutsideBlocks } from "../helpers/componentsOutsideBlocks";

// Disabled ABTest temporaly
// import RedirectsHelper from "../helpers/redirectsHelper";

export default (data: any) => {
  const pageData = data.data.page;

  const navbarComponent = pageData.Navbar;
  const pageComponents = pageData.Blocks;
  const seoComponent = pageData.SEO;
  const footerComponent = pageData.Footer;
  const blocks = componentBuilder(pageComponents);
  // const { ManageRedirects } = RedirectsHelper();

  // useEffect(() => {
  //   ManageRedirects();
  // }, []);

  return (
    <>
      <SeoHelmet data={seoComponent} locale={pageData.locale} customUrl={pageData.CustomUrl} title={pageData.Title}/>
      {navbarComponent && <Navbar data={navbarComponent} />}

      <div className="page-content">
      {blocks?.map((block, id) => {
        const type = block?.props?.data?.__typename;
        if (componentsOutsideBlocks.includes(type)) return null;
        return <div key={id}>{block}</div>;
      })}
      </div>

      {footerComponent && <Footer data={footerComponent} />}
      {blocks?.map((block, id) => {
        const type = block?.props?.data?.__typename;
        if (componentsOutsideBlocks.includes(type))return (
          <React.Fragment key={id}>
            {block}
          </React.Fragment>
        );
        return null;
      })}
    </>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    page: strapiPage(id: { eq: $id }) {
      id
      Title
      CustomUrl
      locale
      SEO {
        SeoTitle
        SeoDescription
        DoNotIndex
        DoNotFollow
        Schema
        CanonicalTag
        TwitterImageUrl
        FacebookImageUrl
        ImageUrl
        Links {
          Href
          HrefLang
        }
        Image {
          url
          alternativeText
          file {
            childImageSharp {
              gatsbyImageData
            }
            url
          }
        }
      }
      Navbar {
        DropdownLabel
        DropdownLinks {
          Label
          Url
        }
        Links {
          Label
          Url
        }
        Phone
        HideAccount
        AccountLabel
        AccountIcon {
          alternativeText
          file {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        AccountUrl
        AccountNoFollow
        AccountNewTab
        ButtonLabel
        ButtonUrl
        ButtonNoFollow
        ButtonNewTab
        idButton
      }
      Footer {
        group_title_1
        group_title_2
        group_title_3
        group_title_4
        group_links_1 {
          Label
          Url
        }
        group_links_2 {
          Label
          Url
        }
        group_links_3 {
          Label
          Url
        }
        group_links_4 {
          Label
          Url
        }
        group_links_bottom {
          Label
          Url
        }
        contact_address
        contact_phone_number
        contact_phone_subtitle
        contact_title
        contact_map_link {
          Label
          Url
          id
        }
        form_header
        form_submit_button_link {
          Label
          Url
        }
        form_text_placeholder
        facebook_url
        instagram_url
        linkedin_url
        twitter_url
        youtube_url
      }

      # BLOCK COMPONENT START
      Blocks {
        __typename
        ... on StrapiComponentComponentPromoBanner {
          id
          Title
          SubTitle
          ButtonLabel
          ButtonUrl
          ButtonNoFollow
          ButtonNewTab
          SelectTitleType {
            TitleTypeSelection
          }
          AnimatedBubbles
        }
        ... on StrapiComponentComponentAccordion {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          Items {
            Text
            Title
            TitleType
          }
          Button {
            Label
            Url
            NoFollow
            NewTab
          }
        }
        ... on StrapiComponentComponentLegalTable {
          id
          Columns {
            Title
          }
          Rows {
            Text
          }
        }
        ... on StrapiComponentComponentUspIcons {
          id
          Items {
            Text
            Title
            TitleTypeSelect
            ImageURL
            AlternativeText
            Icon {
              alternativeText
              file {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          AnimatedBubbles
        }
        ... on StrapiComponentComponentImageBanner {
          id
          DesktopImage {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentFeaturedCards {
          id
          Header
          SubTitle
          SelectTitleType {
            TitleTypeSelection
          }
          Items {
            Colour
            Text
            Title
            FeatureImage {
              alternativeText
              file {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            CardNumber
            ShowMoreLabel
            ShowLessLabel
            FeaturedIcon {
              alternativeText
              file {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            PrimaryButtonLabel
            PrimaryButtonLink
            SecondaryButtonLabel
            SecondaryButtonLink
            PrimaryButtonNoFollow
            PrimaryButtonNewTab
            SecondaryButtonNoFollow
            SecondaryButtonNewTab
            CardTitleType
          }
        }
        ... on StrapiComponentComponentFeatureContent {
          id
          Header
          SelectTitleType {
            TitleTypeSelection
          }
          Items {
            Text
            Title
            FeatureIcon {
              alternativeText
              file {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            ContentTitleType
            ImageURL
            AlternativeText
          }
        }
        ... on StrapiComponentComponentIconsRow {
          id
          IconsList {
            SingleImage {
              alternativeText
              file {
                url
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            ImageURL
            AlternativeText
          }
        }
        ... on StrapiComponentComponentHeader {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          SubTitle
          Text
          TrustPilotScore
          ExcellentText
          ExcellentLink
          HeadersButtons {
            Label
            Secondary
            Url
            NoFollow
            NewTab
          }
          HeaderItems {
            Point
          }
          Icon {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          AnimatedBubbles
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentSecondaryHeader {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          Subtitle
          Text
          Trustpilot
          TrustPilotScore
          ExcellentText
          ExcellentLink
          SecHeaderButtons {
            Label
            Secondary
            Url
            NoFollow
            NewTab
          }
          SecHeaderPoints {
            Point
          }
          SecHeaderImage {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ImageURL
          AlternativeText
        }

        ... on StrapiComponentComponentTertiaryHeader {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
        }

        ... on StrapiComponentComponentWhatsIncluded {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          SubTitle
          TabItem {
            TabTitle
            ContentTitle
            ContentTitleType
            ContentSummary
            PointColumns
            Point1
            Point2
            Point3
            Point4
            Point5
            Point6
            BtnUrl
            BtnLabel
          }
        }
        ... on StrapiComponentComponentCompareTable {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          Headers {
            Competitor
          }
          CompareRows {
            Description
          }
          Values {
            Value
          }
        }
        ... on StrapiComponentComponentCompareWith {
          Title
          SubTitle
          SelectTitleType {
            TitleTypeSelection
          }
          Items {
            Condition
            LeftTitle
            LeftText
            LeftButtonUrl
            LeftButtonLabel
            LeftButtonNoFollow
            LeftButtonNewTab
            RightButtonNoFollow
            RightButtonNewTab
            RightTitle
            RightText
            RightButtonLabel
            RighttButtonUrl
            LeftImageURL
            LeftAlternativeText
            LeftIcon {
              alternativeText
              file {
                url
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            RightImageURL
            RightAlternativeText
            RightIcon {
              alternativeText
              file {
                url
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        ... on StrapiComponentComponentTrustPilot {
          id
          Title
          Button {
            Label
            Url
          }
        }
        ... on StrapiComponentComponentNavigationHeader {
          id
          Title
          Nav
          Location
          ButtonsItem {
            Label
            Url
          }
          Icon {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        ... on StrapiComponentComponentPinkBanner {
          id
          Title
          SubTitle
          FeaturedImage {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          PrimaryButtonLabel
          PrimaryButtonLink
          SecondaryButtonLabel
          SecondaryButtonUrl
          PrimaryButtonNoFollow
          PrimaryButtonNewTab
          SecondaryButtonNoFollow
          SecondaryButtonNewTab
          SelectTitleType {
            TitleTypeSelection
          }
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentProductCarousel {
          id
          Title
          SubTitle
          SelectTitleType {
            TitleTypeSelection
          }
          Cards {
            Title
            Text
            SingleImage {
              alternativeText
              file {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            Price
            PriceFromLabel
            PricePeriodLabel
            Colour
            PrimaryButtonLabel
            PrimaryButtonUrl
            SecondaryButtonLabel
            SecondaryButtonUrl
            PrimaryButtonNoFollow
            PrimaryButtonNewTab
            SecondaryButtonNoFollow
            SecondaryButtonNewTab
            ProductTitleType
            ImageURL
            AlternativeText
          }
        }
        ... on StrapiComponentComponentFeaturedArticles {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          ButtonLabel
          ButtonUrl
          NoFollow
          NewTab
        }
        ... on StrapiComponentComponentPolicyCards {
          id
          Cards {
            Text
            PolicyTitleType
            Title
            CardColour
            SingleImage {
              alternativeText
              file {
                url
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            PrimaryButtonLabel
            PrimaryButtonLink
            PrimaryButtonNoFollow
            PrimaryButtonNewTab
            SecondaryButtonLabel
            SecondaryButtonLink
            SecondaryButtonNoFollow
            SecondaryButtonNewTab
            ImageURL
            AlternativeText
          }
        }
        ... on StrapiComponentComponentFaqListings {
          id
          Title
          SubText
          SearchLabel
          NoResultLabel
          Faqs {
            id
            Title
            Category
            CustomUrl
          }
        }
        ... on StrapiComponentComponentAnimatedLines {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          SubText
          Configuration
          VideoURL
          PrimaryMedia {
            alternativeText
            file {
              url
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          BulletPoints {
            id
            Title
            Text
            ImageURL
            AlternativeText
            Image {
              alternativeText
              file {
                url
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          Link {
            Label
            Url
          }
          Buttons {
            Label
            Secondary
            Url
            NoFollow
            NewTab
          }
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentTrustPilotCarousel {
          id
          Title
          TitleTypeSelect {
            TitleTypeSelection
          }
          TotalReviews
          Score
          LeftMessage
          RightMessage
          LinkToReviews {
            Label
            Secondary
            Url
          }
          ReviewCard {
            Stars
            Review
            FullName
            Date
          }
        }

        # BASIC COMPONENTS
        ... on StrapiComponentBasicButton {
          id
          Secondary
          Label
          Url
          idButton
          NewTab
          NoFollow
        }
        ... on StrapiComponentBasicTitleText {
          id
          Title
          Text
          TitleType
        }
        ... on StrapiComponentBasicTitle {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
        }
        ... on StrapiComponentBasicText {
          id
          Text
        }
        ... on StrapiComponentBasicHtml {
          id
          Html
        }
        ... on StrapiComponentComponentHubspotForm {
          id
          portalId
          formId
          region
        }
        ... on StrapiComponentComponentCalculatorModule {
          id
          Url
        }
        ... on StrapiComponentComponentHeaderCalculator {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          TrustPilotScore  
          Icon {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          AnimatedBubbles
          CalculatorModule {
            Url
          }
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentHeaderCalculatorNoSignUp {
          id
          Title
          SelectTitleType {
            TitleTypeSelection
          }
          TrustPilotScore  
          Icon {
            alternativeText
            file {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          AnimatedBubbles
          CalculatorModule {
            Url
          }
          ImageURL
          AlternativeText
        }
        ... on StrapiComponentComponentSnackbar {
          id
            TitleSnackbar
            Subtitle
            CTA
            URL
            Target
        }
        ... on StrapiComponentComponentButtons {
          id
          BackgroundColor
          HasPrimaryButton
          PrimaryButtonID
          PrimaryButtonLabel
          PrimaryButtonNewTab
          PrimaryButtonNoFollow
          PrimaryButtonURL
          HasSecondaryButton
          SecondaryButtonConfigurationIcon
          SecondaryButtonHasIcon
          SecondaryButtonIconType
          SecondaryButtonID
          SecondaryButtonLabel
          SecondaryButtonNewTab
          SecondaryButtonNoFollow
          SecondaryButtonType
          SecondaryButtonURL
        }
        # ADD NEW COMPONENTS ABOVE THIS POINT ^
      }
    }
  }
`;
